<template>
  <div class="admin">
    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li>Verfügbarkeit</li>
            </ul>
            <div class="header-actions">

            </div>
          </header>

          <div class="admin-body">

            <div class="admin-availability">

              <div class="row row-gutter-20">
                <div class="col-12">
                  <div class="row row-gutter-20">
                    <div class="col-12">

                      <div class="form-wrap select-wrap">
                        <label for="pickup_date">Abholung Datum</label>
                        <v-date-picker v-model='filter.pickup_date' :min-date='new Date()' :input-props='{ class: "form-input"}' />
                      </div>

                    </div>
                    <div class="col-12">

                      <div class="form-wrap select-wrap">
                        <label for="pickup_time">Abholung Uhrzeit</label>
                        <select v-model="filter.pickup_time" id="pickup_time">
                          <option value="06">06:00 Uhr</option>
                          <option value="07">07:00 Uhr</option>
                          <option value="08">08:00 Uhr</option>
                          <option value="09">09:00 Uhr</option>
                          <option value="10">10:00 Uhr</option>
                          <option value="11">11:00 Uhr</option>
                          <option value="12">12:00 Uhr</option>
                          <option value="13">13:00 Uhr</option>
                          <option value="14">14:00 Uhr</option>
                          <option value="15">15:00 Uhr</option>
                          <option value="16">16:00 Uhr</option>
                          <option value="17">17:00 Uhr</option>
                          <option value="18">18:00 Uhr</option>
                          <option value="19">19:00 Uhr</option>
                        </select>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row row-gutter-20">
                    <div class="col-12">

                      <div class="form-wrap select-wrap">
                        <label for="return_date">Rückgabe Datum</label>
                        <v-date-picker v-model='filter.return_date' :min-date='this.filter.pickup_date' :input-props='{ class: "form-input"}' />
                      </div>

                    </div>
                    <div class="col-12">

                      <div class="form-wrap select-wrap">
                        <label for="return_time">Rückgabe Uhrzeit</label>
                        <select v-model="filter.return_time" id="return_time">
                          <option value="06">06:00 Uhr</option>
                          <option value="07">07:00 Uhr</option>
                          <option value="08">08:00 Uhr</option>
                          <option value="09">09:00 Uhr</option>
                          <option value="10">10:00 Uhr</option>
                          <option value="11">11:00 Uhr</option>
                          <option value="12">12:00 Uhr</option>
                          <option value="13">13:00 Uhr</option>
                          <option value="14">14:00 Uhr</option>
                          <option value="15">15:00 Uhr</option>
                          <option value="16">16:00 Uhr</option>
                          <option value="17">17:00 Uhr</option>
                          <option value="18">18:00 Uhr</option>
                          <option value="19">19:00 Uhr</option>
                        </select>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="form-wrap select-wrap">
                <label for="vehicle_groups">Fahrzeuggruppe</label>
                <select v-model="filter.group_id" id="vehicle_groups">
                  <option v-for="group in groups" v-bind:key="group.id" v-bind:value="group.id">{{ group.name }}</option>
                </select>
              </div>

              <a @click="find_vehicles" class="button button-100 button-orange button-small">Fahrzeuge finden</a>

              <div v-if="loading">
                <div class="loading-wrap">
                  <div class="loading-spinner"></div>
                </div>
              </div>
              <div v-else>

                <div v-if="requested">
                  <div class="available-vehicles">
                    <h3>Verfügbare Fahrzeuge</h3>
                    <div v-if="vehicles.length == 0">
                      <div class="alert alert-info">
                        <p>Keine Fahrzeuge zu diesem Zeitpunkt verfügbar.</p>
                      </div>
                    </div>
                    <div v-else>
                      <div v-for="vehicle in vehicles" v-bind:key="vehicle.id" class="vehicle vehicle-available cf">
                        <div class="vehicle-head">
                          <h3>{{ vehicle.license_plate }} <small style="display:block;margin-top:5px;font-weight: 400">{{ vehicle.category.name }}</small><small v-if="vehicle.location" style="display:block;margin-top:5px;font-weight: 400">{{ vehicle.location.name }}</small></h3>
                          <p class="book-now"><a @click="book_now(vehicle, vehicle.category_id, vehicle.location_id)" class="button button-orange button-small">Jetzt Reservierung anlegen ›</a></p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AdminNav from '../../../components/admin_nav'
import moment from 'moment'

export default {
  name: 'admin_calendar',
  components: {
    AdminNav
  },
  data () {
    return {
      date: new Date(),
      filter: {},
      vehicles: [],
      groups: [],
      loading: false,
      requested: false
    }
  },
  methods: {
    set_dates() {
      this.filter.pickup_date = new Date();
    },
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_groups', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.groups = response.data.vehicle_groups;
      })
      .catch(error => {
        console.log(error);
      })

    },
    find_vehicles() {
      if (this.filter.pickup_date && this.filter.pickup_time && this.filter.return_date && this.filter.return_time && this.filter.group_id) {
        this.loading = true;

        axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/available?pickup_date='+this.filter.pickup_date+'&return_date='+this.filter.return_date+'&pickup_time='+this.filter.pickup_time+'&return_time='+this.filter.return_time+'&group_id='+this.filter.group_id, { headers: { Authorization: this.$store.getters.getToken }})
        .then(response => {
          this.vehicles = response.data.vehicles;
          this.loading = false;
          this.requested = true;
        })
        .catch(error => {
          console.log(error);
        })
      } else {
        this.$notify({
          group: 'alert',
          type: 'error',
          title: 'Bitte alle Felder ausfüllen',
        });
      }
    },
    book_now(vehicle, category, location) {
      var pickup_date = moment(this.filter.pickup_date).format("YYYY-MM-DD");
      var return_date = moment(this.filter.return_date).format("YYYY-MM-DD");
      this.$router.push('/admin/reservations/new?pickup_date='+pickup_date+'&return_date='+return_date+'&pickup_time='+this.filter.pickup_time+'&return_time='+this.filter.return_time+'&vehicle_id='+vehicle.id+'&category_id='+category+'&location_id='+location);
    }
  },
  mounted () {
    this.get_data();
    this.set_dates();
  },
  watch: {
    date: function(val) {
      console.log(val);
      this.date = val;
      this.loading = true;
      this.get_data();
    }
  }
}
</script>

<style lang="scss">

  .alert-info {
    background: #f9f9f9;
    border: 1px solid #f1f1f1;
    text-align: center;
  }

  .available-vehicles {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #f1f1f1;

    h3 {
      margin-top: 0;
    }
  }

  .vehicle-available {
    border: 1px dashed #f1f1f1;
    padding: 15px;
    margin-bottom: 10px;

    h3 {
      margin: 0;
    }
  }

  .vehicle-head {
    position: relative;

    .book-now {
      margin: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 600px) {

    .vehicle-available {

      .vehicle-head {
        position: relative;

        .book-now {
          margin-top: 10px;
          position: relative;
        }
      }

    }
  }
</style>
